export interface AppUser {
  id: number | null;
  organization_id: number | null;
  name: string;
  email: string;
  password: string;
  is_verified: boolean;
  is_super: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export class AppUser implements AppUser {
  public id: number | null;
  public organization_id: number | null;
  public name: string;
  public email: string;
  public password: string;
  public is_verified: boolean;
  public is_super: boolean;
  public created_at: string;
  public updated_at: string;
  public deleted_at: string | null;

  public constructor() {
    this.id = null;
    this.organization_id = null;
    this.name = "";
    this.email = "";
    this.password = "";
    this.is_verified = false;
    this.is_super = false;
    this.created_at = "";
    this.updated_at = "";
    this.deleted_at = null;
  }
}
