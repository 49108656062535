
// Vue
import { Options, Vue } from "vue-class-component";

// Vendors
import vClickOutside from "click-outside-vue3";

// Store
import store from "@/store";

// Models
import { AppUser } from "@/models/app/user";

// Components
import { ElContainer } from "element-plus";
import { User } from "@element-plus/icons-vue";

// Services
import { postRequest } from "@/services/api/request";

@Options({
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ElContainer,
    User,
  },
})
export default class Header extends Vue {
  isUserMenuBoxVisible = false;

  get user(): AppUser {
    return store.getters.getUser;
  }

  setIsUserMenuBoxVisible(): void {
    this.isUserMenuBoxVisible = false;
  }

  async setLogout(): Promise<void> {
    await postRequest({
      uri: "/authentication/logout",
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then(() => {
      store.dispatch("setLogout");

      this.$router.push({
        name: "HomeIndex",
      });
    });
  }
}
