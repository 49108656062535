
// Vue
import { Options, Vue } from "vue-class-component";

// Store
import store from "@/store";

// Components
import { ElMenu, ElMenuItemGroup, ElMenuItem, ElSubMenu } from "element-plus";

@Options({
  components: {
    ElMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElSubMenu,
  },
})
export default class Navigation extends Vue {
  get isUserSuper(): boolean {
    return store.getters.getUser.is_super === true;
  }

  get isUserAdministrator(): boolean {
    return store.getters.getUser.is_super === false;
  }

  setRoute(routeName: string): void {
    this.$router.push({
      name: routeName,
    });
  }
}
