// Router
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";

// Store
import store from "@/store/index";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getUser.is_super) {
    case true:
      next({ name: "AppSuperAdministrationDashboardIndex" });
      break;
    default:
      next({ name: "AppAdministrationDashboardIndex" });
      break;
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    next();
  } else if (to.meta.protectedRoute && store.getters.getAuthentication.token === "") {
    next({ name: "HomeIndex" });
  } else if (!to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "privacy-policy-index" */ "../views/privacy-policy/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUseIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "terms-of-use-index" */ "../views/terms-of-use/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery/change-password/:token",
    name: "AuthenticationPasswordRecoveryChangePasswordIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-change-password-index" */ "../views/authentication/password-recovery/change-password/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/dashboard",
    name: "AppAdministrationDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-dashboard-index" */ "../views/app/administration/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects",
    name: "AppAdministrationProjectsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-index" */ "../views/app/administration/projects/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/:project_id/view",
    name: "AppAdministrationProjectsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-projects-detail-index" */ "../views/app/administration/projects/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/budgets",
    name: "AppAdministrationProjectsBudgetsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-budgets-index" */ "../views/app/administration/projects/budgets/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/budgets/:budget_id/view",
    name: "AppAdministrationProjectsBudgetsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-budgets-view-index" */ "../views/app/administration/projects/budgets/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/budget-headers/:budget_header_id/view",
    name: "AppAdministrationProjectsBudgetHeadersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-budgets-header-view-index" */ "../views/app/administration/projects/budget-headers/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/budget-lines",
    name: "AppAdministrationProjectsBudgetLinesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-budget-lines-index" */ "../views/app/administration/projects/budget-lines/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/budget-lines/:budget_line_id/view",
    name: "AppAdministrationProjectsBudgetLinesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-budget-lines-view-index" */ "../views/app/administration/projects/budget-lines/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/projects/donors",
    name: "AppAdministrationProjectsDonorsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-projects-donors-index" */ "../views/app/administration/projects/donors/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/expenses",
    name: "AppAdministrationExpensesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-expenses-expenses-index" */ "../views/app/administration/expenses/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/expenses/:expense_id/view",
    name: "AppAdministrationExpensesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-expenses-view-index" */ "../views/app/administration/expenses/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/expenses/expense-categories",
    name: "AppAdministrationExpensesExpenseCategoriesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-expenses-expense-categories-index" */ "../views/app/administration/expenses/expense-categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/expenses/expense-statuses",
    name: "AppAdministrationExpensesExpenseStatusesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-expenses-expense-statuses-index" */ "../views/app/administration/expenses/expense-statuses/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/expenses/expense-types",
    name: "AppAdministrationExpensesExpenseTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-expenses-expense-types-index" */ "../views/app/administration/expenses/expense-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/cash-register/cash-register-incomes",
    name: "AppAdministrationCashRegisterCashRegisterIncomesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-cash-register-cash-register-incomes-index" */ "../views/app/administration/cash-register/cash-register-incomes/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/cash-register/cash-register-expenses",
    name: "AppAdministrationCashRegisterCashRegisterExpensesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-cash-register-cash-register-expenses-index" */ "../views/app/administration/cash-register/cash-register-expenses/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/bank-statements",
    name: "AppAdministrationBankStatementsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-bank-statements-index" */ "../views/app/administration/bank-statements/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/bank-statements/:bank_statement_id/view",
    name: "AppAdministrationBankStatementsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-bank-statements-view-index" */ "../views/app/administration/bank-statements/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/bank-statements/:bank_statement_id/view/bank-statement-transaction/:bank_statement_transaction_id",
    name: "AppAdministrationBankStatementsViewBankStatementTransactionIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-bank-statements-view-bank-statement-transaction-index" */ "../views/app/administration/bank-statements/view/bank-statement-transaction/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/bank-statements/bank-statement-statuses",
    name: "AppAdministrationBankStatementsBankStatementStatusesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-bank-statements-bank-statement-statuses-index" */ "../views/app/administration/bank-statements/bank-statement-statuses/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/bank-statements/bank-accounts",
    name: "AppAdministrationBankStatementsBankAccountsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-bank-statements-bank-accounts-index" */ "../views/app/administration/bank-statements/bank-accounts/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/organizations",
    name: "AppAdministrationOrganizationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-organizations-index" */ "../views/app/administration/organizations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/partners",
    name: "AppAdministrationPartnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-partners-index" */ "../views/app/administration/partners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/repositories",
    name: "AppAdministrationRepositoriesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-repositories-index" */ "../views/app/administration/repositories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/repositories/:repository_id/view",
    name: "AppAdministrationRepositoriesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-repositories-view-index" */ "../views/app/administration/repositories/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/settings/users",
    name: "AppAdministrationSettingsUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-settings-users-index" */ "../views/app/administration/settings/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/settings/currencies",
    name: "AppAdministrationSettingsCurrenciesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-settings-currencies-index" */ "../views/app/administration/settings/currencies/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/settings/units",
    name: "AppAdministrationSettingsUnitsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-settings-units-index" */ "../views/app/administration/settings/units/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/settings/budget-line-types",
    name: "AppAdministrationSettingsBudgetLineTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-settings-budget-line-types-index" */ "../views/app/administration/settings/budget-line-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/settings/date-types",
    name: "AppAdministrationSettingsDateTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-settings-date-types-index" */ "../views/app/administration/settings/date-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/settings/document-types",
    name: "AppAdministrationSettingsDocumentTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-settings-document-types-index" */ "../views/app/administration/settings/document-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/super-administration/dashboard",
    name: "AppSuperAdministrationDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-super-administration-dashboard-index" */ "../views/app/super-administration/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/super-administration/settings/users",
    name: "AppSuperAdministrationSettingsUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-super-administration-settings-users-index" */ "../views/app/super-administration/settings/users/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
